<template>
  <div class="brand-info">
    <h1 class="brand-info__title">{{ dataSeo?.content_title }}</h1>
    <h3 class="brand-info__description">
      {{ dataSeo?.content_description }}
    </h3>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => route.path === `/${item.alias}`) || seoData.value.find((item) => item.alias === '/')
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/brand-info.scss" />
